'use strict';

import React from 'react';
import Helmet from 'react-helmet';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';
import { FeatureTiles } from '@latitude/feature-tiles';
import Section from '@latitude/section';

import styled from 'styled-components';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PaymentOptions, {
  PaymentOptionsColumn
} from '@/components/PaymentOptions/PaymentOptions';
import Text from '@/components/Text/Text';
import Lframe from '@/components/Lframe/Lframe';
import { Strong } from '@/components/Text/Bold';

import ContactingLatitude from './_contacting-latitude';
import imageHero from './images/how-to-protect-yourself-background.png';
import imgIdCareLogo from './images/idcare-logo.png';
import imgAcscLogo from './images/acsc-logo.png';
import imgEsafetyCommLogo from './images/esafety-commissioner-logo.png';
import imgScamWatchLogo from './images/scamwatch-logo.png';
import PageData from './data/how-to-protect-yourself.json';
import './how-to-protect-yourself.css';

const SITE_URL = 'https://www.latitudefinancial.com.au';

const HowToProtectYourself = props => {
  return (
    <Layout location={props.location} title={PageData.title}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${SITE_URL}${props.location.pathname}`}
          />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>

        <Lframe bgImage={imageHero} />
        <HeroBranded
          className="bg-lightest"
          title={PageData.hero.title}
          text={PageData.hero.text}
          breadcrumbs={PageData.hero.breadcrumbs}
          css={`
            @media (min-width: ${BREAKPOINT.MD}) {
              .HeroTitle {
                padding-top: 40px;
              }
              .HeroText {
                padding-bottom: 25px;
              }
            }
          `}
        />

        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          trackId="how-to-protect-yourself-stickynav"
        />

        <Section className="pt-lg-5 pb-lg-5">
          <div
            id={PageData.stickyNavigation[0].anchor}
            css={`
              margin: 0 10%;
              padding: 15pt;
            `}
          >
            <SectionHeading>
              {PageData.stickyNavigation[0].label}
            </SectionHeading>
            <Text
              css={`
                margin: 0 10%;
                padding: 15pt;
                text-align: center;
              `}
            >
              We urge you to remain vigilant to scams. Please note, we will
              never contact our customers requesting their passwords.
            </Text>
            <Text>
              <p>
                <strong>Be on high alert for phishing scams</strong>
                <br />
                Scammers may contact you by SMS, phone, email or post,
                pretending to be from Latitude or Gem.
              </p>
              <p>
                <strong>
                  Verify any communications you receive to ensure they are
                  authentic
                </strong>
                <br />
                Inspect the sender's email address for anomalies and be wary of
                phone numbers originating from unusual geographic locations.
              </p>
              <p>
                <strong>Be careful with links and attachments</strong>
                <br />
                Instead of clicking links within emails or SMS, navigate to our
                website through your browser or visit our app. Do not open
                attachments from unsolicited contact.
              </p>
              <p>
                <strong>
                  Do not respond to SMS or emails from suspicious numbers or
                  email addresses
                </strong>
                <br />
                If you have received a Latitude or Gem branded scam, please
                report them to{' '}
                <a href="mailto:scams@latitudefinancial.com">
                  scams@latitudefinancial.com
                </a>{' '}
                and then delete the message.
              </p>
              <p>
                <strong>Monitor your bank accounts</strong>
                <br />
                If you spot any unusual or suspicious activity, notify your bank
                immediately.
              </p>
              <p>
                <strong>Use strong and unique passwords</strong>
                <br />
                Regularly update your passwords and ensure you are using strong
                passwords. Enable multi-factor authentication where possible.
              </p>
            </Text>
          </div>
        </Section>

        <Section className="security-common-scams">
          <SectionHeading id={PageData.stickyNavigation[1].anchor}>
            {PageData.stickyNavigation[1].label}
          </SectionHeading>
          <SubHeading>
            Familiarise yourself with the most common types of scams to help
            prevent falling victim to them.
          </SubHeading>
          <PaymentOptions
            className="security-common-scams"
            css={`
              max-width: 1110px;
              margin: 0px auto;
            `}
            data={[
              {
                id: 'phishing-scams',
                title: 'Phishing Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Phishing is a technique scammers use to trick you into
                        providing personal information such as usernames and
                        passwords, credit card details or other personal data,
                        or to download malicious software on your device.
                        Scammers may contact you by email, phone call or text
                        message, often pretending to be from a legitimate
                        business.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a phishing scam</Strong>
                        <ul>
                          <li>
                            They have not addressed you by your proper name.
                          </li>
                          <li>
                            Spelling errors and grammatical mistakes in the
                            message.
                          </li>
                          <li>
                            Similar but slightly incorrect email addresses,
                            logos and URLs.
                          </li>
                          <li>Requests for your one-time password.</li>
                          <li>
                            Use of threatening language and the use of time
                            pressure to convince you to act.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Before clicking on a link, hover your mouse over it
                            to see the actual web address.
                          </li>
                          <li>
                            Contact the organisation through an independent
                            search to verify the legitimacy of the contact.
                          </li>
                          <li>Never give out your one-time passwords.</li>
                          <li>
                            Don&apos;t open an attachment if you weren&apos;t
                            expecting it.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'online-shopping-scams',
                title: 'Online Shopping Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Scammers pretend to be legitimate online sellers, either
                        with a fake website or a fake ad on a genuine retailer
                        site, to deceive people into buying from them. Once they
                        have received payment for the product, the shopper might
                        receive the item, but they&apos;ll be fake, or they may
                        not receive anything at all.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of an online shopping scam:</Strong>
                        <ul>
                          <li>
                            Price, benefits or features of the product sound too
                            good to be true.
                          </li>
                          <li>Requesting non-secure payment methods.</li>
                          <li>
                            Information about privacy, terms and conditions or
                            the contact details are inadequate.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of offers that seem too good to be true.
                          </li>
                          <li>
                            Avoid feeling pressured to transfer money to the
                            other party.
                          </li>
                          <li>
                            Check the reviews of the merchant, online store and
                            its products.
                          </li>
                          <li>
                            Be cautious of missing privacy policies, terms and
                            conditions or refund information.
                          </li>
                          <li>
                            Look for &apos;https&apos; and a closed padlock icon
                            in the web browser when making payments online.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'investment-scams',
                title: 'Investment Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Scammers may contact you via phone or email offering you
                        an investment opportunity promising big payouts, quick
                        money or guaranteed returns.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of an investment scam:</Strong>
                        <ul>
                          <li>Promises of high returns with low risks.</li>
                          <li>
                            Unsolicited contact offering advice on investments.
                          </li>
                          <li>Requesting non-secure payment methods.</li>
                          <li>
                            Information about privacy, terms and conditions or
                            the contact details are inadequate.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of offers that seem too good to be true and
                            are time-sensitive.
                          </li>
                          <li>
                            Do your own research and get professional advice.
                          </li>
                          <li>
                            Don&apos;t provide your details or respond to
                            unsolicited contact from people you don&apos;t know.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'romance-scams',
                title: 'Romance Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Romance scammers use fake online profiles and
                        declarations of love to create a false sense of trust to
                        gain access to your funds.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a romance scam:</Strong>
                        <ul>
                          <li>
                            Declarations of strong feelings towards you just
                            after a few contacts.
                          </li>
                          <li>
                            Their online profile is not consistent with what
                            they tell you.
                          </li>
                          <li>Messages are usually poorly written.</li>
                          <li>
                            Requests for money, gifts or your financial details.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>Research your potential partner online.</li>
                          <li>
                            Conduct a reverse-image search (such as Google or
                            TinEye) to see if the photo has been posted
                            elsewhere on the internet.
                          </li>
                          <li>
                            Be cautious when sharing pictures or videos,
                            especially when you haven&apos;t met them.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'job-scams',
                title: 'Job Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Jobs and employment scams trick you into handing over
                        your money by offering you a &apos;guaranteed&apos; way
                        to make fast money or to obtain a high-paying job for
                        little effort. Scammers may contact you by email, phone
                        call or text message.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a job scam</Strong>
                        <ul>
                          <li>
                            Offers of high paying jobs that require low effort.
                          </li>
                          <li>
                            Demands for an upfront fee to secure a guaranteed
                            job or large returns.
                          </li>
                          <li>
                            Requests of money to be transferred on behalf of
                            someone else.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of job offers from people or companies you
                            don&apos;t know.
                          </li>
                          <li>Research the company offering the job.</li>
                          <li>
                            Be cautious of bogus employment ads on legitimate
                            job sites.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            Í
            tabContentMinHeight="385px"
          />
        </Section>

        <IndustryPartnersSection
          id={PageData.stickyNavigation[2].anchor}
          title={PageData.stickyNavigation[2].label}
        />

        <ContactingLatitude
          anchor={PageData.stickyNavigation[3].anchor}
          heading={PageData.stickyNavigation[3].label}
        />
      </main>
    </Layout>
  );
};

export default HowToProtectYourself;

const SectionHeading = styled.h2`
  margin: 40px 0;
  color: #000;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 72px;
    font-size: 32px;
    line-height: 40px;
  }
`;

const SubHeading = styled(Text)`
  text-align: ${ALIGN.CENTER};
  color: ${COLOR.GREY75};
  margin: 0 auto ${MARGIN.M24};
  @media (min-width: ${BREAKPOINT.SM}) {
    margin-bottom: ${MARGIN.M32};
  }
`;

const IndustryPartnersSection = ({ id, title }) => {
  const tileCtaLabel = 'Find Out More';

  const data = [
    {
      icon: imgIdCareLogo,
      content:
        "IDCARE is Australia and New Zealand's national identity and cyber support service. A not-for-profit charity that was formed to address a critical support gap for individuals confronting identity and cyber security concerns.",
      ctaHref: 'https://www.idcare.org/',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-idcare'
    },
    {
      icon: imgAcscLogo,
      content:
        "The Australian Cyber Security Center (ACSC) provides advice and information about how to protect you, your family and your business online. They lead the Australian Government's efforts to improve cyber security and provide current alerts, and useful resources to help you protect yourself online. <a href='https://www.cyber.gov.au/acsc/report' target='_blank'>ReportCyber</a> is a secure reporting service for cybercrime and online incidents for investigation by law enforcement.",
      ctaHref: 'http://www.cyber.gov.au',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-acsc'
    },
    {
      icon: imgEsafetyCommLogo,
      content:
        'The website brings together a wealth of useful information, support, and guidance to help Australians have safe, enjoyable experiences online.',
      ctaHref: 'http://www.esafety.gov.au/',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-esafety'
    },
    {
      icon: imgScamWatchLogo,
      content:
        "Scamwatch provides information to consumers and small businesses about how to recognise, avoid and report scams. The ACCC and Scamwatch ask that you report scam-related events. This information is used to keep Australians informed about the latest scams in circulation and to collaborate with the industry to look for innovative ways to disrupt scams.<p>You can report a scam to the ACCC by completing the <a href='https://www.scamwatch.gov.au/report-a-scam' target='_blank'>Report a Scam</a> web form.</p>",
      ctaHref: 'http://www.scamwatch.gov.au',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-scamwatch'
    }
  ];

  return (
    <Section className="security-industry-partners">
      <div
        css={`
          max-width: 1024px;
          margin: 0 auto;
        `}
      >
        <FeatureTiles
          id={id}
          className="security-industry-partner"
          heading={title}
          tiles={data}
          noBackgroundStyles
          useLocalAssets
          tileColumns={2}
        />
      </div>
    </Section>
  );
};
